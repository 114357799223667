import {MapOverlayText, MapOverlayHeading} from "./style";

function MapContext() {
    return(
        <>
        <MapOverlayHeading> Aegilean Isle </MapOverlayHeading>
            <MapOverlayText>
                Lorem Ipsum.
            </MapOverlayText>
        </>
    );
}
export default MapContext;